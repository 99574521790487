<script>
import {AddressEditor} from '@/components/shared'
export default {
  name: 'EditForm',
  props: ['type'],
  components: {
    AddressEditor
  },
  data() {
    return {
      discountTypes: [
        {text: this.$t('shared.choose'), value: null},
        {text: this.$t('shared.amount'), value: 'AMOUNT'},
        {text: this.$t('shared.percent'), value: 'PERCENT'}
      ],
      model: {
        active: false,
        amount: null,
        availableFrom: null,
        availableUntil: null,
        claimLimit: null,
        discountType: 'AMOUNT',
        // pin: 'string',
        reusable: false,
        userId: null,
        user: {}
      }
    }
  },
  computed: {
    discountLabel() {
      if (this.model.discountType === 'PERCENT') {
        return this.$t('shared.percent')
      } else {
        return this.$t('shared.IRR')
      }
    },
    discountValidations() {
      const validation = {
        numeric: true,
        required: true
      }
      if (this.model.discountType === 'PERCENT') {
        validation.min_value = 0
        validation.max_value = 100
      }
      return validation
    }
  },
  methods: {
    searchUser(filter) {
      return this.$api.users.getAllInfo(filter)
    },
    selectUser() {
      if (!this.model.user) return Promise.resolve({})
      const item = {
        text: this.model.user.fullName,
        value: this.model.user.id
      }
      return Promise.resolve(item)
    }
  }
}
</script>
