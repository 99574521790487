<template>
  <v-row dense>
    <v-col cols="12" md="6">
      <div v-if="showCode" class="d-flex justify-space-between align-center mb-3">
        <div>
          {{ $t('trips.memberShipCode') }} :
          <span v-if="model.memberShipCode" class="font-weight-bold error--text">
            {{ model.memberShipCode }}
          </span>
          <span v-else class="error--text">- - - - - -</span>
        </div>
        <v-btn color="success" small @click="generateCode" :disabled="!!model.memberShipCode">
          {{ $t('trips.memberShipCodeGenerate') }}
        </v-btn>
      </div>

      <c-input v-model="model.name" :label="$t('shared.name')" :rules="'max:30'" />

      <c-input
        textarea
        v-model="model.address"
        :label="$t('shared.address')"
        :rules="'required|max:500'"
        rows="3"
      />

      <v-row dense>
        <v-col cols="12" sm="6">
          <c-input v-model="model.plate" :label="$t('shared.plate')" :rules="'required'" />
        </v-col>
        <v-col cols="12" sm="6">
          <numeric-editor
            :locale-string="false"
            v-model="model.unit"
            :label="$t('shared.unit')"
            :rules="'max:30|numeric'"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <numeric-editor :locale-string="false" v-model="model.zipCode" :label="$t('shared.zipCode')" />
        </v-col>

        <v-col cols="12" sm="6">
          <numeric-editor
            :locale-string="false"
            v-model="model.phone"
            :label="$t('shared.phone')"
            :rules="'required|numeric|max:11'"
          />
        </v-col>
      </v-row>

      <c-input v-model="model.comment" :label="$t('merchants.comment')" :rules="'max:300'" />
    </v-col>

    <v-col md="6">
      <geopoint-editor :value="model" height="420" @select="selectAddress" @update="selectAddress" />
      <input v-show="false" v-model="model.lat" :rules="'required'" :name="$t('shared.location')" />
    </v-col>
  </v-row>
</template>

<script>
import GeopointEditor from './GeopointEditor'
export default {
  name: 'AddressEditor',
  props: {
    value: {type: Object, required: true},
    showCode: Boolean
  },
  components: {
    GeopointEditor
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    selectAddress(item) {
      this.value.address = item.label
    },
    generateCode() {
      this.$api.memberShips
        .create()
        .then((res) => {
          this.model.memberShipCode = res
        })
        .catch((err) => {
          this.$showError(err)
        })
    }
  }
}
</script>
