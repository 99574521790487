<template>
  <span>
    <span> {{ value.address }} </span>
    <span v-if="value.plate"> - {{ $t('shared.plate') }} {{ value.plate }} </span>
    <span v-if="value.unit"> - {{ $t('shared.unit') }} {{ value.unit }} </span>
    <div class="text-no-wrap mt-2" v-if="value.phone"> {{ $t('shared.phone') }} : {{ value.phone }} </div>
    <div class="text-no-wrap mt-2" v-if="value.zipCode"> {{ $t('shared.zipCode') }} : {{ value.zipCode }} </div>
    <div class="text-no-wrap mt-2" v-if="value.memberShipCode"> {{ $t('trips.memberShipCode') }} : {{ value.memberShipCode }} </div>
  </span>
</template>

<script>
export default {
  name: 'AddressDispaly',
  props: {
    value: {type: Object, required: true}
  }
}
</script>
