<template>
  <div class="text-center">
    <v-tooltip top>
      <template v-slot:activator="{on}">
        <v-btn small fab text color="red lighten-2" v-on="on" @click="dialog = true">
          <v-icon> mdi-security </v-icon>
        </v-btn>
      </template>
      <span>{{ $t('roles.permissions') }}</span>
    </v-tooltip>

    <v-dialog v-model="dialog" width="700" persistent>
      <page-loading :loading="initializing" />

      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{ $t('roles.permissions') }}
        </v-card-title>

        <v-tabs fixed-tabs @change="changeTab">
          <v-tab v-if="!type || type === 'admin'">
            {{ $t('account.admin') }}
          </v-tab>

          <v-tab v-if="!type || type === 'merchant'">
            {{ $t('merchants.merchant') }}
          </v-tab>
        </v-tabs>

        <v-card-text class="mt-6" style="min-height: 86px">
          <auto-complete
            v-if="!initializing"
            v-model="model.roleIds"
            multiple
            :label="$t('roles.roles')"
            :search="searchRoles"
            :select="selectRoles"
          />
        </v-card-text>

        <v-divider></v-divider>

        <div class="px-4 py-3 d-flex justify-space-between">
          <v-btn color="success" :loading="progressing" @click="submit">
            <v-icon class="me-1"> mdi-check </v-icon>
            {{ $t('shared.save') }}
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn color="secondary" :loading="progressing" @click="dialog = false">
            {{ $t('shared.return') }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'RoleEditor',
  props: {
    userId: {type: [String, Number], required: true},
    type: {type: String, default: null}
  },
  data() {
    return {
      initializing: true,
      progressing: false,
      dialog: false,
      currentType: this.type || 'admin',
      selectedRoles: [],
      tabMapper: {
        0: 'admin',
        1: 'merchant'
      },
      model: {
        roleIds: [],
        type: null
      }
    }
  },
  methods: {
    changeTab(tab) {
      this.currentType = this.type || this.tabMapper[tab]
      this.getRoles()
    },
    getRoles() {
      this.initializing = true
      return this.$api.users.getRoles(this.userId, this.currentType).then((res) => {
        this.model.roleIds = res.roleIds
        this.selectedRoles = res.roles
        this.initializing = false
      })
    },
    searchRoles(filter) {
      filter.active = true
      return this.$api.roles.getAll(filter, this.currentType).then((res) => {
        return res.map((i) => ({text: i.name, value: i.id}))
      })
    },
    selectRoles() {
      const items = this.selectedRoles.map((item) => {
        return {
          text: item.name,
          value: item.id
        }
      })
      return Promise.resolve(items)
    },
    submit() {
      this.progressing = true
      this.model.type = this.currentType.toUpperCase()
      return this.$api.users
        .updateRoles(this.userId, this.model)
        .then(() => {
          this.dialog = false
        })
        .finally((res) => {
          this.progressing = false
        })
    }
  }
}
</script>

<style lang="scss" scoped></style>
